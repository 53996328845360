import { TaskListTask } from './types'
import { apiClient_UNSTABLE } from '../api/api-client-unstable'
import { RunbookTaskBulkDeleteResponse } from '../api/data-providers/runbook-types'

export type TaskListResponseType = {
  tasks: TaskListTask[]
  meta: {
    permissions: {
      create: [number?]
      in_progress_update: [number?]
      add_snippet: [number?]
    }
  }
}

let controller: AbortController | undefined

export const getTasks = async (runbookId: number | string, runbookVersionId: number | string) => {
  if (controller) controller.abort()
  controller = new AbortController()
  const signal = controller.signal

  const { data } = await apiClient_UNSTABLE.get<TaskListResponseType>(
    `runbooks/${runbookId}/runbook_versions/${runbookVersionId}/tasks`,
    {
      signal
    }
  )
  return data
}

export const deleteTasks = async (runbookId: number | string, runbookVersionId: number | string, taskIds: number[]) => {
  if (controller) controller.abort()
  controller = new AbortController()
  const signal = controller.signal

  const { data } = await apiClient_UNSTABLE.patch<RunbookTaskBulkDeleteResponse>(
    `runbooks/${runbookId}/runbook_versions/${runbookVersionId}/tasks/bulk_delete`,
    { task_ids: taskIds },
    {
      signal
    }
  )
  return data
}
