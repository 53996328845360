import {
  FieldValue,
  RunbookShowRunbook,
  RunbookVersion,
  StreamListStream,
  TaskBase,
  TaskLevel,
  TaskShowTask,
  TaskType
} from './types'
import { Snippet } from 'main/services/queries/use-snippets'
import { apiClient } from '../api'
import {
  RunbookTaskBulkSkipResponse,
  RunbookTaskCreateResponse,
  RunbookTaskFinishResponse,
  RunbookTaskQuickUpdateResponse,
  RunbookTaskStartResponse,
  RunbookTaskUpdateResponse,
  RunbookTaskValidateAbandonResponse
} from '../api/data-providers/runbook-types'

export type TaskGetTaskResponseMeta = {
  permissions: {
    update: [number?]
    destroy: [number?]
    skip: [number?]
    modify_comms: [number?]
    modify_duration: [number?]
    in_progress_update: [number?]
  }
  version_data: Pick<
    RunbookVersion,
    | 'id'
    | 'start_planned'
    | 'streams_count'
    | 'tasks_count'
    | 'completed_tasks_count'
    | 'completion'
    | 'timing_mode'
    | 'start_scheduled'
    | 'end_scheduled'
    | 'end_actual'
    | 'end_forecast'
    | 'start_actual'
    | 'end_planned'
    | 'start_display'
    | 'end_display'
    | 'duration_diff'
    | 'duration_display'
    | 'start_diff'
    | 'end_diff'
    | 'resumed_at'
    | 'paused_at'
    | 'cancelled_at'
    | 'stage'
    | 'runbook_id'
  >
}
export type TaskGetTaskResponse = {
  meta: TaskGetTaskResponseMeta
  task: TaskShowTask
}

export const getTask = async ({
  runbookId,
  runbookVersionId,
  taskId
}: {
  runbookId: number | string
  runbookVersionId: number | string
  taskId: number | string
}) => {
  const { data } = await apiClient.get<TaskGetTaskResponse>({
    url: `runbooks/${runbookId}/runbook_versions/${runbookVersionId}/tasks/${taskId}`
  })

  return data
}

export type TaskEditTaskPayload = {
  task: Pick<
    TaskBase,
    | 'id'
    | 'name'
    | 'description'
    | 'duration'
    | 'stream_id'
    | 'task_type_id'
    | 'start_requirements'
    | 'end_requirements'
    | 'start_fixed'
    | 'end_fixed'
    | 'message'
    | 'level'
    | 'disable_notify'
    | 'field_values_attributes'
    | 'auto_finish'
  >
  predecessors: number[]
  recipients: {
    users: number[]
    runbook_teams: number[]
  }
  users: number[]
  runbook_teams: number[]
  linked_snippets?: Snippet[]
}

export type TaskEditRestrcitedTaskPayload = {
  task: Pick<TaskBase, 'message' | 'end_requirements' | 'duration' | 'disable_notify' | 'name' | 'description'>
  recipients: {
    users: number[]
    runbook_teams: number[]
  }
  users: number[]
  runbook_teams: number[]
  linked_snippets?: Snippet[]
}

export const setTask = async ({
  runbookId,
  runbookVersionId,
  taskId,
  payload
}: {
  runbookId: number | string
  runbookVersionId: number | string
  taskId: number | string
  payload: TaskEditTaskPayload | TaskEditRestrcitedTaskPayload
}) => {
  const { data } = await apiClient.patch<
    TaskEditTaskPayload | TaskEditRestrcitedTaskPayload,
    RunbookTaskUpdateResponse
  >({
    url: `runbooks/${runbookId}/runbook_versions/${runbookVersionId}/tasks/${taskId}`,
    data: payload
  })

  return data
}

export type TaskStartPayload = {
  taskId?: number
  override: boolean
  field_values_attributes: FieldValue[]
  selected_successor_ids: number[]
}

export const startTask = async ({
  runbookId,
  runbookVersionId,
  taskId,
  payload
}: {
  runbookId: number | string
  runbookVersionId: number | string
  taskId: number | string
  payload: TaskStartPayload
}) => {
  const { data } = await apiClient.patch<TaskStartPayload, RunbookTaskStartResponse>({
    url: `runbooks/${runbookId}/runbook_versions/${runbookVersionId}/tasks/${taskId}/start`,
    data: payload
  })

  return data
}

export type TaskFinishPayload = {
  taskId?: number
  override: boolean
  field_values_attributes: FieldValue[]
  selected_successor_ids: number[]
}

export const finishTask = async ({
  runbookId,
  runbookVersionId,
  taskId,
  payload
}: {
  runbookId: number | string
  runbookVersionId: number | string
  taskId: number | string
  payload: TaskStartPayload
}) => {
  const { data } = await apiClient.patch<TaskFinishPayload, RunbookTaskFinishResponse>({
    url: `runbooks/${runbookId}/runbook_versions/${runbookVersionId}/tasks/${taskId}/finish`,
    data: payload
  })

  return data
}

export type TaskBulkSkipPayload = {
  comment: string
  task_ids: number[]
}

export const bulkSkipTasks = async ({
  runbookId,
  runbookVersionId,
  payload
}: {
  runbookId: number | string
  runbookVersionId: number | string
  payload: TaskBulkSkipPayload
}) => {
  const { data } = await apiClient.patch<TaskBulkSkipPayload, RunbookTaskBulkSkipResponse>({
    url: `runbooks/${runbookId}/runbook_versions/${runbookVersionId}/tasks/bulk_skip`,
    data: payload
  })

  return data
}

type TaskCreateTaskPayload = {
  auto_finish?: boolean | null
  disable_notify: boolean
  duration?: number
  end_fixed: number | null
  level: TaskLevel
  level_name?: string
  name: string
  stage: 'new'
  start_display?: number
  stream: StreamListStream
  stream_id: number
  task_type: TaskType
  task_type_id: number
}

export type TaskCreatePayload = {
  task: TaskCreateTaskPayload
  base_task_id: null | number
  successors: number[]
  predecessors: number[]
  users: number[]
  runbook_teams: number[]
  integration_events: any[]
  runBook?: RunbookShowRunbook // yes the property name is correct..
}

export const createTask = async ({
  runbookId,
  runbookVersionId,
  payload
}: {
  runbookId: number | string
  runbookVersionId: number | string
  payload: TaskCreatePayload
}) => {
  const { data } = await apiClient.post<TaskCreatePayload, RunbookTaskCreateResponse>({
    url: `runbooks/${runbookId}/runbook_versions/${runbookVersionId}/tasks`,
    data: payload
  })

  return data as RunbookTaskCreateResponse
}

export type TaskTestPayload = {
  message: string | null
  type: 'comms_email' | 'comms_sms'
}

export const testTaskComms = async ({
  runbookId,
  runbookVersionId,
  taskId,
  payload
}: {
  runbookId: number | string
  runbookVersionId: number | string
  taskId: number | string
  payload: TaskTestPayload
}) => {
  return await apiClient.patch<TaskTestPayload, any>({
    url: `runbooks/${runbookId}/runbook_versions/${runbookVersionId}/tasks/${taskId}/test`,
    data: payload
  })
}
export type QuickUpdateTaskPayload = {
  users?: number[]
  runbook_teams?: number[]
}

export const quickUpdateTask = async ({
  runbookId,
  runbookVersionId,
  taskId,
  payload
}: {
  runbookId: number | string
  runbookVersionId: number | string
  taskId: number | string
  payload: QuickUpdateTaskPayload
}) => {
  const { data } = await apiClient.patch<QuickUpdateTaskPayload, RunbookTaskQuickUpdateResponse>({
    url: `runbooks/${runbookId}/runbook_versions/${runbookVersionId}/tasks/${taskId}/quick_update`,
    data: payload
  })

  return data
}

export type ValidateAbandonTaskPayload = {
  selected_successor_ids: number[]
}

export const validateAbandonTask = async ({
  runbookId,
  runbookVersionId,
  taskId,
  payload
}: {
  runbookId: number | string
  runbookVersionId: number | string
  taskId: number | string
  payload: ValidateAbandonTaskPayload
}) => {
  const { data } = await apiClient.patch<ValidateAbandonTaskPayload, RunbookTaskValidateAbandonResponse>({
    url: `runbooks/${runbookId}/runbook_versions/${runbookVersionId}/tasks/${taskId}/validate_abandon`,
    data: payload
  })

  return data as RunbookTaskValidateAbandonResponse
}

export const fireIntegration = async ({
  runbookId,
  runbookVersionId,
  taskId
}: {
  runbookId: number | string
  runbookVersionId: number | string
  taskId: number | string
}) => {
  await apiClient.patch({
    url: `runbooks/${runbookId}/runbook_versions/${runbookVersionId}/tasks/${taskId}/fire_endpoint`
  })
}

export const cancelIntegration = async ({
  runbookId,
  runbookVersionId,
  taskId
}: {
  runbookId: number | string
  runbookVersionId: number | string
  taskId: number | string
}) => {
  await apiClient.patch({
    url: `runbooks/${runbookId}/runbook_versions/${runbookVersionId}/tasks/${taskId}/cancel_endpoint`
  })
}
