import { ReactNode } from 'react'
import { FieldValues } from 'react-hook-form'

import {
  Box,
  Button,
  Form as FormElement,
  IconName,
  LoadingPanel,
  Message,
  Modal,
  ModalProps,
  Text
} from '@cutover/react-ui'
import { Form, FormProps, FormRenderProps, FormType } from './form'
import { useScrollIntoView } from 'main/components/shared/hooks/use-scroll-into-view'

export type FormModalProps<
  TFieldValues extends FieldValues,
  TApiValues extends Record<string, any> = TFieldValues
> = Omit<
  FormProps<TFieldValues, TApiValues> &
    Omit<
      ModalProps,
      | 'confirmText'
      | 'title'
      | 'titleIcon'
      | 'titleSuffix'
      | 'hideFooter'
      | 'loading'
      | 'customButton'
      | 'onClickConfirm'
    >,
  'children'
> & {
  children?: ReactNode | ((props: FormRenderProps<TFieldValues>) => ReactNode)
  steps?: number
  confirmText: string | ((step: number) => string)
  title?: string | ((step: number) => string)
  titleIcon?: ReactNode | ((step: number) => ReactNode)
  titleSuffix?: ReactNode | ((step: number) => ReactNode)
  hideFooter?: boolean | ((step: number) => boolean)
  description?: ReactNode | ((step: number) => ReactNode)
  onClickCustomButton?: (step: number) => (values: TFieldValues) => Promise<any>
  onClickConfirm?: (formContext: FormRenderProps<TFieldValues>) => Promise<any>
  customButtonText?: string | ((step: number) => string)
  customButtonIcon?: IconName | ((step: number) => IconName)
  /** State of initial loading of the content needed to display in the modal, not the confirm button on submit */
  loading?: boolean
  preventAutoClose?: boolean
  customErrors?: string[]
  formRef?: React.RefObject<FormType<TFieldValues>>
}

export const FormModal = <TFieldValues extends FieldValues, TApiValues extends Record<string, any> = TFieldValues>({
  animate,
  children,
  cancelIcon,
  cancelText,
  'data-testid': dataTestId,
  hasCancelButton,
  confirmIcon,
  confirmText,
  confirmDisabled,
  onClickCustomButton,
  customButtonText,
  customButtonIcon,
  hideFooter,
  loading,
  loadingText,
  onClickBack,
  onClose,
  onAfterClose,
  customErrors,
  focusConfirmButton,
  open: isOpen = false,
  title,
  titleIcon,
  titleSuffix,
  width,
  zIndex,
  onClickConfirm,
  description,
  steps,
  preventAutoClose,
  formElementWrapper = true,
  formRef,
  ...formProps
}: FormModalProps<TFieldValues, TApiValues>) => {
  const { ref: formWrapperRef, scrollIntoView: scrollTabsIntoView } = useScrollIntoView()

  return isOpen ? (
    <Form {...formProps} formElementWrapper={false} ref={formRef}>
      {renderProps => {
        const {
          formState: { isSubmitting, isSubmitted, isSubmitSuccessful },
          trigger,
          watch,
          setValue,
          handleSubmit,
          onSubmit,
          getValues,
          errorMessage
        } = renderProps

        // @ts-ignore
        const currentStep: number = watch('_step', 1)

        const descriptionContent = typeof description === 'function' ? description(currentStep) : description

        const customButtonHandler = onClickCustomButton?.(currentStep)
        const customButton = customButtonHandler ? (
          <Button
            tertiary
            icon={typeof customButtonIcon === 'function' ? customButtonIcon(currentStep) : customButtonIcon}
            onClick={() => {
              const { _step, ...values } = getValues()
              // @ts-ignore
              customButtonHandler(values)
            }}
            label={typeof customButtonText === 'function' ? customButtonText(currentStep) : customButtonText}
          />
        ) : undefined

        const allErrors = [...(customErrors ?? []), ...(errorMessage ?? [])]
        const hasErrors = allErrors.length > 0

        const content = typeof children === 'function' ? children(renderProps) : children

        return (
          <Modal
            animate={animate}
            cancelIcon={cancelIcon}
            cancelText={cancelText}
            data-testid={dataTestId}
            confirmDisabled={confirmDisabled}
            hasCancelButton={hasCancelButton}
            confirmIcon={steps && currentStep && currentStep < steps ? 'arrow-forward' : confirmIcon}
            confirmText={typeof confirmText === 'function' ? confirmText(currentStep) : confirmText}
            customButton={customButton}
            title={typeof title === 'function' ? title(currentStep) : title}
            titlePrefix={typeof titleIcon === 'function' ? titleIcon(currentStep) : titleIcon}
            titleSuffix={typeof titleSuffix === 'function' ? titleSuffix(currentStep) : titleSuffix}
            hideFooter={typeof hideFooter === 'function' ? hideFooter(currentStep) : hideFooter}
            // this loading is for the confirm button submit status, not the loading overlay of the modal content when opening
            loading={preventAutoClose ? isSubmitting : isSubmitting || (isSubmitSuccessful && !allErrors.length)}
            loadingText={loadingText}
            onClickBack={
              !!onClickBack || (steps && currentStep > 1)
                ? () => {
                    onClickBack?.()
                    // @ts-ignore
                    setValue('_step', currentStep - 1)
                  }
                : undefined
            }
            onClose={onClose}
            // Passing undefined here disabled the button. Confirm button is disabled only while content is loading into the modal.
            onClickConfirm={
              // loading here is the loading of the whole modal
              loading
                ? undefined
                : async () => {
                    const isValid = await trigger()
                    if (isValid) {
                      // Perform final form submission if this is the last form step
                      if (!steps || currentStep === steps) {
                        handleSubmit(onSubmit)()
                        // Transition to next step
                      } else if (steps) {
                        onClickConfirm?.(renderProps)
                        if (currentStep < steps) {
                          // @ts-ignore
                          setValue('_step', currentStep + 1)
                        }
                      }
                      // Scroll to form error message when form is invalid
                    } else {
                      handleSubmit(() => true)()
                      scrollTabsIntoView()
                    }
                  }
            }
            onAfterClose={() => {
              onAfterClose?.()
              onClose?.() // this could cause issues if not aware this happens on submit
            }}
            focusConfirmButton={focusConfirmButton}
            open={
              isOpen && (preventAutoClose || !isSubmitted || (isSubmitSuccessful && hasErrors) || !isSubmitSuccessful)
            }
            width={width}
            zIndex={zIndex}
          >
            <Box gap="medium" css="position: relative" ref={formWrapperRef}>
              {loading && <LoadingPanel />}
              {typeof descriptionContent === 'string' ? <Text>{descriptionContent}</Text> : descriptionContent}
              <Message type="error" message={allErrors} />
              {formElementWrapper ? (
                <FormElement css="height: 100%" onSubmit={e => e.preventDefault()}>
                  {content}
                </FormElement>
              ) : (
                content
              )}
            </Box>
          </Modal>
        )
      }}
    </Form>
  ) : null
}
