import { useParams } from 'react-router-dom'

import { ScrollContent } from '@cutover/react-ui'
import { RunbookWidgetCollection } from 'main/components/dashboards/dashboard-widget-collection'
import {
  useAccountMetaProperty,
  useAppliedFilters,
  useFilteredTasksState,
  useRunbookVersionUsers,
  useTaskList
} from 'main/recoil/data-access'
import {
  ActiveRunbookModel,
  CommentModel,
  ConfigModel,
  CurrentUserModel,
  CustomFieldModel,
  StreamModel,
  TaskTypeModel
} from 'main/data-access'

export const Dashboard = () => {
  const { dashboardId } = useParams()
  const currentUserId = CurrentUserModel.useId()
  const customFields = CustomFieldModel.useGetAll()
  const runbook = ActiveRunbookModel.useGet()
  const streams = StreamModel.useGetAll()
  const taskTypes = TaskTypeModel.useGetAll()
  const tasks = useTaskList()
  const { taskStageTypes: taskStages } = ConfigModel.useGet()
  const dashboards = useAccountMetaProperty({ attribute: 'dashboards' })
  const dashboard = dashboards.find(dashboard => dashboard.id === Number(dashboardId))
  const filtersState = useAppliedFilters()

  const { state: commentsLoadingState, contents: featuredCommentContents } = CommentModel.useGetAllLoadable({
    scope: 'featured'
  })
  const comments = commentsLoadingState === 'hasValue' ? featuredCommentContents : []
  const filteredTasks = useFilteredTasksState()
  const users = useRunbookVersionUsers()

  const components =
    dashboard?.dashboard_components.map(component => {
      return {
        ...component,
        type: component.component_type,
        custom_fields: customFields,
        current_user: currentUserId,
        runbook,
        streams,
        task_types: taskTypes,
        tasks,
        filtered_tasks: filteredTasks,
        stage_types: taskStages,
        users,
        comments,
        filters: component.filters && JSON.parse(component.filters),
        task_filters: filtersState
      }
    }) || []

  return <ScrollContent>{tasks && <RunbookWidgetCollection components={components} media={'screen'} />}</ScrollContent>
}
