import { useEffect, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { eventManager } from 'event-manager'
import { Controller, FormProvider, useForm, UseFormReturn } from 'react-hook-form'
import * as yup from 'yup'
import { useNavigate, useParams } from 'react-router-dom'

import { toCamelCase } from '@cutover/api'
import {
  Box,
  EditPanel,
  Form,
  IconButton,
  Menu,
  MenuListItem,
  MenuListItemProps,
  Message,
  TextInput
} from '@cutover/react-ui'
import { TeamUnlinkModal } from './team-unlink-modal'
import { UserSelect } from '../../../../../components/shared/form/user-select'
import { ReplaceTeamModals } from '../replace-team/replace-team-modals'
import { Permissions, SelectedTeam, ValidationRunbookTeam } from '../types'
import {
  RunbookTeam,
  RunbookTeamUpdate,
  RunbookTeamUser,
  useRunbookTeamQuery,
  useRunbookTeamUpdate
} from '../use-runbook-team'
import { useLanguage } from 'main/services/hooks'
import { useIsOnReactRunbook } from 'main/services/routing'
import { useProcessRunbookTeamUpdateResponse } from 'main/recoil/data-access/updaters__TEMPORARY/runbook-team-operations'
import { User } from 'main/services/queries/types'
import { CurrentUserModel } from 'main/data-access'

type TeamDetailsProps = {
  accountId: number
  runbookId: number
  runbookVersionId: number
  team: SelectedTeam
  onClose: () => void
  onBack: () => void
  runbookTeams: ValidationRunbookTeam[]
  setRunbookTeams: (array: ValidationRunbookTeam[]) => void
  permissions: Permissions
  bypassNotification?: boolean
}

export type RunbookTeamUserForm = Omit<RunbookTeamUser, 'id'> & { id: string | number; key: string }

export type RunbookTeamForm = {
  name: string
  users: RunbookTeamUserForm[]
}

const schema = yup.object().shape({
  name: yup.string().required()
})

export function TeamDetails({
  accountId,
  runbookId,
  runbookVersionId,
  team,
  onClose,
  onBack,
  runbookTeams,
  setRunbookTeams,
  permissions,
  bypassNotification = false
}: TeamDetailsProps) {
  const user = toCamelCase(CurrentUserModel.useGet())
  const { translate } = useLanguage()
  const [isUnlinking, setIsUnlinking] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [previousTeamName, setPreviousTeamName] = useState<string | null>(null)
  const [isTeamReplaceModalOpen, setIsTeamReplaceModalOpen] = useState<boolean>(false)
  const processRunbookTeamUpdateResponse = useProcessRunbookTeamUpdateResponse()
  const isOnReactRunbook = useIsOnReactRunbook()

  const { data, isLoading, isSuccess } = useRunbookTeamQuery({
    userId: user?.id,
    runbookId,
    runbookVersionId,
    teamId: team.id
  })

  const canUnlink = data && data.canUpdate && data.linked && !isLoading

  const mutation = useRunbookTeamUpdate({
    userId: user?.id,
    runbookId,
    runbookVersionId,
    teamId: team.id,
    originalRunbook: data
  })

  const methods = useForm<RunbookTeamForm>({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      name: team.name,
      users: []
    }
  })

  useEffect(() => {
    if (!previousTeamName && data) {
      setPreviousTeamName(data.name)
    }
  }, [data])

  // NOTE: the below makes no sense, it causes a bug where duplciate users can be added
  // to the multiselect, since it doesn't think they are used (compares string id with number id)
  // But when below is removed, the multiselect does not show selected items
  useEffect(() => {
    if (data) {
      methods.reset({
        name: data.name ?? '',
        users: (data.users ?? []).map(user => ({
          ...user,
          id: `${user.id}`
        }))
      })
    }
  }, [methods.reset, data])

  useEffect(() => {
    eventManager.on('runbook-team-deleted', onDelete)

    return () => {
      eventManager.off('runbook-team-deleted', onDelete)
    }
  }, [])

  const onReset = () => {
    if (data) {
      methods.reset({ name: data.name, users: data.users })
    }
    setErrorMessage(null)
  }

  const findRemovedUsers = (updatedUsers?: RunbookTeamUserForm[]) => {
    const previousUsers = data?.users
    const updatedUserIds = updatedUsers?.map(user => Number(user.id)) || []
    const usersToRemove = previousUsers?.filter(({ id }) => !updatedUserIds.includes(id))

    return usersToRemove
  }

  const updateRunbookTeams = (index: number, team: ValidationRunbookTeam) => {
    if (index !== -1) {
      setRunbookTeams([...runbookTeams.slice(0, index), team, ...runbookTeams.slice(index + 1)])
    }
  }

  const continueUpdate =
    (updatedData: RunbookTeamForm) =>
    async (data?: { usersToRemoveFromRunbook: number[]; usersToReassign: RunbookTeamUser[] }, reassignProps?: {}) => {
      const payload: RunbookTeamUpdate = {
        name: updatedData.name,
        reassign: reassignProps,
        data: data
      }

      payload.userIds = (updatedData.users ?? []).map(user => Number(user.id))

      await mutation.mutate(payload, {
        onSuccess: response => {
          if (isOnReactRunbook) {
            processRunbookTeamUpdateResponse(response)
          }
        }
      })
      if (updatedData.name !== previousTeamName) {
        const indexOfPreviousName = runbookTeams.findIndex(team => team.name === previousTeamName)
        updateRunbookTeams(indexOfPreviousName, { name: updatedData.name, linked: team.linked })
      }
    }

  const onFormSubmit = async (updatedData: RunbookTeamForm) => {
    const removedUsers = findRemovedUsers(updatedData.users)

    if (removedUsers && removedUsers.length > 0) {
      // TODO: will not work when react_runbook feature flag is on
      eventManager.emit('open-delete-team-reassign-modal', {
        removedUsers: removedUsers,
        continueUpdate: continueUpdate(updatedData)
      })
    } else {
      await continueUpdate(updatedData)()
    }
  }

  const onDelete = ({ deletedRunbookTeamName }: { deletedRunbookTeamName: string }) => {
    // remove deleted team from runbookTeams
    setRunbookTeams(runbookTeams.filter(team => team.name !== deletedRunbookTeamName))
    onBack()
  }

  const onUnlinkSave = () => {
    // unlink the runbook team in runbookTeams for FE validation
    const indexOfPreviousRunbookTeam = runbookTeams.findIndex(team => team.name === data?.name)

    if (data) {
      updateRunbookTeams(indexOfPreviousRunbookTeam, { name: data.name, linked: false })
    }

    setIsUnlinking(false)
  }

  const closeTeamReplaceModal = () => {
    setIsTeamReplaceModalOpen(false)
  }

  useEffect(() => {
    if (mutation.isError) {
      setErrorMessage(mutation.error.validationError(translate('runbook:peoplePanel:teams:updateError')))
    }
  }, [mutation.isError])

  return (
    <EditPanel
      title={
        data?.linked
          ? translate('runbook:peoplePanel:teams:linkedTeamHeading')
          : translate('runbook:peoplePanel:teams:customTeamHeading')
      }
      isDirty={methods.formState.isDirty}
      onSubmit={() => methods.handleSubmit(onFormSubmit)()}
      onBack={onBack}
      onClose={onClose}
      headerItems={[
        ...(methods.formState.isDirty || !data?.canDelete
          ? []
          : [
              <HeaderContent
                runbookTeam={data}
                setIsReplaceModalOpen={setIsTeamReplaceModalOpen}
                permissions={permissions}
                onUnlink={canUnlink ? () => setIsUnlinking(true) : undefined}
              />
            ])
      ]}
      onReset={onReset}
      isSubmitting={methods.formState.isSubmitting || mutation.isLoading}
    >
      <>
        <RunbookTeamForm
          serverError={errorMessage}
          accountId={accountId}
          methods={methods}
          onUnlink={() => setIsUnlinking(true)}
          isLoading={isLoading}
          isSuccess={isSuccess}
          data={data}
        />
        <TeamUnlinkModal
          isOpen={isUnlinking}
          runbookId={runbookId}
          runbookVersionId={runbookVersionId}
          teamId={team.id}
          onClose={() => setIsUnlinking(false)}
          onCancel={() => setIsUnlinking(false)}
          onSave={onUnlinkSave}
        />
        <ReplaceTeamModals
          isOpen={isTeamReplaceModalOpen}
          runbookTeamName={team.name}
          runbookTeamLinked={team.linked}
          accountId={accountId}
          runbookId={runbookId}
          runbookVersionId={runbookVersionId}
          runbookTeamId={team.id}
          runbookTeams={runbookTeams}
          setRunbookTeams={setRunbookTeams}
          closeModal={closeTeamReplaceModal}
          bypassNotification={bypassNotification}
        />
      </>
    </EditPanel>
  )
}

type RunbookTeamFormProps = {
  serverError: string | null
  accountId: number
  methods: UseFormReturn<RunbookTeamForm>
  isLoading?: boolean
  isSuccess?: boolean
  onUnlink: () => void
  data?: RunbookTeam
}

function RunbookTeamForm({ serverError, accountId, methods, isLoading, data }: RunbookTeamFormProps) {
  const { t } = useLanguage()

  const canUpdate = data && data.canUpdate && !data.linked && !isLoading

  const nameErrorMessage = methods.formState?.errors?.name?.message
  const errors = nameErrorMessage || serverError

  return (
    <FormProvider {...methods}>
      <Form
        aria-label="form"
        css={`
          height: 100%;
          display: flex;
          flex-direction: column;
        `}
      >
        {errors && (
          <Box margin={{ bottom: 'medium' }}>
            <Message type="error" message={errors} />
          </Box>
        )}

        <TextInput
          {...methods.register('name')}
          hasError={!!nameErrorMessage}
          label={t('runbook:peoplePanel:teams:teamName')}
          disabled={!canUpdate}
          required
          truncate
        />

        <Box data-testid="runbook-team-members">
          <Controller
            name="users"
            control={methods.control}
            render={({ field: { onChange, value, onBlur, ref } }) => {
              return (
                <UserSelect
                  disabled={!canUpdate}
                  minChars={2}
                  accountId={accountId}
                  value={(value || []) as unknown as User[]}
                  inputRef={ref}
                  onBlur={onBlur}
                  label={t('runbook:peoplePanel:teams:teamMembers')}
                  onChange={onChange}
                  draggable
                />
              )
            }}
          />
        </Box>
      </Form>
    </FormProvider>
  )
}

type HeaderProps = {
  runbookTeam?: RunbookTeam
  setIsReplaceModalOpen: (value: boolean) => void
  permissions: Permissions
  onUnlink?: () => void
}

function HeaderContent({ runbookTeam, permissions, setIsReplaceModalOpen, onUnlink }: HeaderProps) {
  const { t } = useLanguage()
  const navigate = useNavigate()
  const { accountId: accountSlug } = useParams()

  const openDeleteTeamModal = () => {
    // TODO: will not work when react_runbook feature flag is on
    eventManager.emit('open-delete-team-modal', runbookTeam)
  }

  const openReplaceTeamModals = () => {
    setIsReplaceModalOpen(true)
  }

  const openCentralTeamsSettings = () => {
    navigate(`/app/${accountSlug}/settings/teams`, { state: { teamId: runbookTeam?.teamId } })
  }

  const menuItems: MenuListItemProps[] = [
    {
      icon: 'swap',
      onClick: openReplaceTeamModals,
      label: t('runbook:peoplePanel:teams:replaceHeaderTitle')
    },
    {
      icon: 'delete',
      onClick: openDeleteTeamModal,
      label: t('common:deleteTeamButton')
    }
  ]

  const editCentralTeamLink: MenuListItemProps = {
    icon: 'new-message',
    label: t('runbook:peoplePanel:teams:editCentralTeam'),
    onClick: openCentralTeamsSettings
  }

  if (permissions.canUpdateCentralTeams && runbookTeam?.linked) {
    menuItems.unshift(editCentralTeamLink)
  }

  if (onUnlink) {
    menuItems.unshift({
      icon: 'unlink',
      label: t('runbook:peoplePanel:teams:unlinkTooltip'),
      onClick: onUnlink
    })
  }

  return (
    <Menu
      trigger={
        <IconButton
          tertiary
          disableTooltip
          data-testid="runbook-team-more-options"
          label={t('common:moreOptions')}
          icon="more-vertical"
        />
      }
    >
      {menuItems.map(item => (
        <MenuListItem
          icon={item.icon}
          label={item.label}
          key={item.label}
          onClick={item.onClick}
          destructive={item.icon === 'delete'}
        />
      ))}
    </Menu>
  )
}
